import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Config from '../../../config.json';
import styles from './ConsultantApplication.module.css';
import ButtonStyles from '../../styles/buttons.module.css';
import TextStyles from '../../styles/text.module.css';

// Status Badge Component
const StatusBadge = ({ status }) => {
    const getStatusClass = () => {
        switch (status) {
            case 'pending':
                return styles.statusPending;
            case 'more-info':
                return styles.statusMoreInfo;
            case 'approved':
                return styles.statusApproved;
            case 'rejected':
                return styles.statusRejected;
            default:
                return '';
        }
    };

    return (
        <span className={`${styles.statusBadge} ${getStatusClass()}`}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
        </span>
    );
};

// Application Card Component
const ApplicationCard = ({ application, isSelected, onClick }) => (
    <div
        style={{
            position: 'relative', overflow: 'clip',
            borderLeft: application.isLive ? '3px solid black' : null
        }}
        onClick={onClick}
        className={`${styles.applicationCard} ${isSelected ? styles.applicationCardSelected : ''}`}
    >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h3 style={{ margin: 0, fontSize: '1rem', fontWeight: 'bold' }}>{application.name}</h3>
            <StatusBadge status={application.status} />
        </div>
        <p style={{ margin: '0.5rem 0 0 0', fontSize: '0.875rem', color: '#6b7280' }}>
            Applied {new Date(application.createdAt).toLocaleDateString()}
        </p>
    </div >
);

// Debug Section Component
const DebugSection = ({ selectedApp, documents }) => {
    const [showDebug, setShowDebug] = useState(false);

    return (
        <div style={{ marginBottom: '1rem' }}>
            <Button
                variant="light"
                size="sm"
                onClick={() => setShowDebug(!showDebug)}
                style={{ marginBottom: showDebug ? '1rem' : 0 }}
            >
                {showDebug ? 'Hide Debug Info' : 'Show Debug Info'}
            </Button>

            {showDebug && (
                <div style={{
                    padding: '1rem',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                    fontSize: '12px'
                }}>
                    <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                        {JSON.stringify({
                            application: {
                                id: selectedApp._id,
                                governmentId: selectedApp.governmentId,
                                proofOfAttendance: selectedApp.proofOfAttendance
                            },
                            documents: {
                                governmentIdFile: documents.governmentIdFile ? {
                                    exists: true,
                                    size: `${(documents.governmentIdFile.data.length * 0.75 / 1024).toFixed(2)} KB`,
                                    contentType: documents.governmentIdFile.contentType
                                } : 'not present',
                                proofOfAttendanceFile: documents.proofOfAttendanceFile ? {
                                    exists: true,
                                    size: `${(documents.proofOfAttendanceFile.data.length * 0.75 / 1024).toFixed(2)} KB`,
                                    contentType: documents.proofOfAttendanceFile.contentType
                                } : 'not present'
                            }
                        }, null, 2)}
                    </pre>
                </div>
            )}
        </div>
    );
};

// Document Section Component
const DocumentSection = ({ documents, selectedApp }) => {
    const getExtensionFromMimeType = (mimeType) => {
        switch (mimeType) {
            case 'application/pdf':
                return '.pdf';
            case 'image/jpeg':
                return '.jpg';
            case 'image/png':
                return '.png';
            default:
                return '';
        }
    };

    const handleDownload = (fileData, fileType, prefix) => {
        try {
            const byteCharacters = atob(fileData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: fileType });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${prefix}-${selectedApp._id}${getExtensionFromMimeType(fileType)}`;
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Download error:', error);
            alert('Error downloading file: ' + error.message);
        }
    };

    return (
        <section className={styles.documentSection}>
            <DebugSection selectedApp={selectedApp} documents={documents} />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                {documents.governmentIdFile && (
                    <div className={styles.documentCard}>
                        <p style={{ fontSize: '0.875rem', fontWeight: 500, marginBottom: '0.5rem' }}>
                            Government ID
                            <span style={{ color: '#6b7280', marginLeft: '0.5rem' }}>
                                ({(documents.governmentIdFile.data.length * 0.75 / 1024).toFixed(2)} KB)
                            </span>
                        </p>
                        <div className={styles.documentPreview}>
                            {documents.governmentIdFile.contentType.startsWith('image/') ? (
                                <img
                                    src={`data:${documents.governmentIdFile.contentType};base64,${documents.governmentIdFile.data}`}
                                    alt="Government ID"
                                    onError={(e) => {
                                        console.error('Error loading image');
                                        e.target.style.display = 'none';
                                        e.target.parentElement.innerHTML = 'Error loading preview';
                                    }}
                                    className={styles.documentImage}
                                />
                            ) : (
                                <div className={styles.pdfPlaceholder}>
                                    {documents.governmentIdFile.contentType === 'application/pdf' ? 'PDF Document' : 'Unknown File Type'}
                                </div>
                            )}
                        </div>
                        <Button
                            variant="outline-primary"
                            onClick={() => handleDownload(
                                documents.governmentIdFile.data,
                                documents.governmentIdFile.contentType,
                                'government-id'
                            )}
                            style={{ marginTop: '0.5rem', width: '100%' }}
                        >
                            Download
                        </Button>
                    </div>
                )}
                {documents.proofOfAttendanceFile && (
                    <div className={styles.documentCard}>
                        <p style={{ fontSize: '0.875rem', fontWeight: 500, marginBottom: '0.5rem' }}>
                            Proof of Attendance
                            <span style={{ color: '#6b7280', marginLeft: '0.5rem' }}>
                                ({(documents.proofOfAttendanceFile.data.length * 0.75 / 1024).toFixed(2)} KB)
                            </span>
                        </p>
                        <div className={styles.documentPreview}>
                            {documents.proofOfAttendanceFile.contentType.startsWith('image/') ? (
                                <img
                                    src={`data:${documents.proofOfAttendanceFile.contentType};base64,${documents.proofOfAttendanceFile.data}`}
                                    alt="Proof of Attendance"
                                    onError={(e) => {
                                        console.error('Error loading image');
                                        e.target.style.display = 'none';
                                        e.target.parentElement.innerHTML = 'Error loading preview';
                                    }}
                                    className={styles.documentImage}
                                />
                            ) : (
                                <div className={styles.pdfPlaceholder}>
                                    {documents.proofOfAttendanceFile.contentType === 'application/pdf' ? 'PDF Document' : 'Unknown File Type'}
                                </div>
                            )}
                        </div>
                        <Button
                            variant="outline-primary"
                            onClick={() => handleDownload(
                                documents.proofOfAttendanceFile.data,
                                documents.proofOfAttendanceFile.contentType,
                                'proof-of-attendance'
                            )}
                            style={{ marginTop: '0.5rem', width: '100%' }}
                        >
                            Download
                        </Button>
                    </div>
                )}
            </div>
        </section>
    );
};

// Detail View Component
const DetailView = ({
    selectedApp,
    messagesByAppId,
    setMessagesByAppId,
    documents,
    loadDocuments,
    handleUpdateStatus
}) => {
    if (!selectedApp) {
        return (
            <div className={styles.emptyState}>
                <p>Select an application to view details</p>
            </div>
        );
    }

    const handleMessageChange = (e) => {
        setMessagesByAppId(prev => ({
            ...prev,
            [selectedApp._id]: e.target.value
        }));
    };

    return (
        <div >
            <div style={{ marginBottom: '2rem' }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                    <p className={TextStyles.BoldLarge} style={{ marginBottom: '0.25rem' }}>{selectedApp.name}</p>
                    <StatusBadge status={selectedApp.status} />
                </div>
                {selectedApp.phone && (
                    <p className={TextStyles.Body} style={{ color: '#6b7280', margin: 0 }}>
                        📞 {selectedApp.phone}
                    </p>
                )}
                {selectedApp.acknowledgement && (
                    <div style={{
                        backgroundColor: '#f9fafb',
                        padding: '0.75rem',
                        borderRadius: '0.375rem',
                        marginTop: '0.75rem'
                    }}>
                        <p className={TextStyles.Body} style={{
                            fontStyle: 'italic',
                            color: '#4b5563',
                            margin: 0
                        }}>
                            Acknowledgement: "{selectedApp.acknowledgement}"
                        </p>
                    </div>
                )}
            </div>

            <section style={{ marginBottom: '2rem' }}>
                <p className={TextStyles.BoldLarge}>About</p>
                <p>{selectedApp.aboutMe}</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <p className={TextStyles.BoldLarge}>Education</p>
                {Array.isArray(selectedApp.education) ? (
                    selectedApp.education.map((edu, index) => (
                        <div key={edu._id || index} style={{ marginBottom: '1rem', padding: '1rem', backgroundColor: '#f9fafb', borderRadius: '0.5rem' }}>
                            <p style={{ fontWeight: 600, margin: 0 }}>{edu.title}</p>
                            <p style={{ margin: '0.25rem 0 0 0', fontSize: '0.875rem', color: '#6b7280' }}>
                                {edu.startYear} - {edu.endYear}
                            </p>
                        </div>
                    ))
                ) : (
                    <p>No education information provided</p>
                )}
            </section>

            {!documents ? (
                <button
                    className={ButtonStyles.StandardDark}
                    onClick={() => loadDocuments(selectedApp._id)}
                    style={{ width: '100%', marginBottom: '2rem' }}
                >
                    Load Documents
                </button>
            ) : (
                <DocumentSection documents={documents} selectedApp={selectedApp} />
            )}

            <section style={{ marginTop: '2rem', marginBottom: 32 }}>
                <p className={TextStyles.BoldLarge}>Message to Applicant</p>
                <textarea
                    value={messagesByAppId[selectedApp._id] || ''}
                    onChange={handleMessageChange}
                    style={{
                        width: '100%',
                        padding: '0.75rem',
                        borderRadius: '0.25rem',
                        border: '1px solid #e5e7eb',
                        minHeight: '100px'
                    }}
                    placeholder="Enter message to applicant..."
                />
            </section>

            <div style={{ display: 'flex', gap: 8 }}>
                <button
                    className={ButtonStyles.StandardDark}
                    style={{ backgroundColor: '#1e3799' }}
                    onClick={() => handleUpdateStatus('approved')}
                >
                    Approve
                </button>
                <button
                    className={ButtonStyles.StandardDark}
                    onClick={() => handleUpdateStatus('rejected')}
                >
                    Reject
                </button>
                <button
                    className={ButtonStyles.StandardDark}
                    onClick={() => handleUpdateStatus('more-info')}
                >
                    Request Info
                </button>
            </div>
        </div>
    );
};

// Main Component
const ConsultantApplications = () => {
    const navigate = useNavigate();
    const [applications, setApplications] = useState({ pending: [], reviewed: [] });
    const [selectedApp, setSelectedApp] = useState(null);
    const [messagesByAppId, setMessagesByAppId] = useState({});
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState(null);

    useEffect(() => {
        fetchApplications();
    }, []);

    useEffect(() => {
        setDocuments(null);
    }, [selectedApp]);

    const fetchApplications = async () => {
        try {
            const response = await axios.get(`${Config.API_BASE_URL}/consultant/applications`);
            const apps = response.data;

            setApplications({
                pending: apps.filter(app => app.status === 'pending' || app.status === 'more-info'),
                reviewed: apps.filter(app => app.status !== 'pending' && app.status !== 'more-info')
            });
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    const loadDocuments = async (applicationId) => {
        try {
            console.log('Fetching documents for application:', applicationId);
            const response = await axios.get(`${Config.API_BASE_URL}/consultant/consultant-application/${applicationId}`);
            console.log('Document response:', response.data);

            if (!response.data.application || (!response.data.governmentIdFile && !response.data.proofOfAttendanceFile)) {
                console.error('Invalid document data structure:', response.data);
                alert('Error loading documents: Invalid data received');
                return;
            }

            setDocuments(response.data);
        } catch (err) {
            console.error('Error loading documents:', err);
            alert(`Error loading documents: ${err.message}`);
        }
    };

    const handleUpdateStatus = async (status) => {
        try {
            await axios.put(`${Config.API_BASE_URL}/consultant/application/${selectedApp._id}/status`, {
                status,
                message: messagesByAppId[selectedApp._id] || ''
            });
            setMessagesByAppId(prev => ({
                ...prev,
                [selectedApp._id]: ''
            }));
            await fetchApplications();
        } catch (err) {
            console.error(err);
            alert('Error updating application status');
        }
    };

    if (loading) {
        return <div style={{ padding: '1rem' }}>Loading...</div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <button className={ButtonStyles.StandardDark} onClick={() => navigate(-1)}>
                        ← Back
                    </button>
                    <h1 className={TextStyles.Title} style={{ margin: 0, fontSize: '1.5rem' }}>Consultant Applications</h1>
                </div>
            </div>

            <div className={styles.mainContent}>
                <div className={styles.listPane}>
                    <div className={styles.applicationsList}>
                        <h2 className={styles.sectionTitle}>
                            Pending ({applications.pending.length})
                        </h2>
                        {applications.pending.map(app => (
                            <ApplicationCard
                                key={app._id}
                                application={app}
                                isSelected={selectedApp?._id === app._id}
                                onClick={() => setSelectedApp(app)}
                            />
                        ))}

                        <h2 className={styles.sectionTitle}>
                            Reviewed ({applications.reviewed.length})
                        </h2>
                        {applications.reviewed.map(app => (
                            <ApplicationCard
                                key={app._id}
                                application={app}
                                isSelected={selectedApp?._id === app._id}
                                onClick={() => setSelectedApp(app)}
                            />
                        ))}
                    </div>
                </div>

                <div className={styles.detailPane}>
                    <DetailView
                        selectedApp={selectedApp}
                        messagesByAppId={messagesByAppId}
                        setMessagesByAppId={setMessagesByAppId}
                        documents={documents}
                        loadDocuments={loadDocuments}
                        handleUpdateStatus={handleUpdateStatus}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConsultantApplications;